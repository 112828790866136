<template>
    <div>
        <!--    <b-alert show variant="light" class="alert alert-elevate">-->
        <!--      <div class="alert-icon">-->
        <!--        <i class="flaticon-warning kt-font-brand"></i>-->
        <!--      </div>-->
        <!--      <div class="alert-text">-->
        <!--        <b>Data tables</b> The <code>v-data-table</code> component is used for-->
        <!--        displaying tabular data. Features include sorting, searching,-->
        <!--        pagination, inline-editing, header tooltips, and row selection.-->
        <!--        <a-->
        <!--          class="kt-link kt-link&#45;&#45;brand kt-font-bold"-->
        <!--          href="https://vuetifyjs.com/en/components/data-tables"-->
        <!--          target="_blank"-->
        <!--        >-->
        <!--          See documentation.-->
        <!--        </a>-->
        <!--      </div>-->
        <!--    </b-alert>-->

        <div v-permission="'contract.create'">
            <div class="row">
                <div class="col-md-12">
                    <!--begin:: Widgets/Activity-->
                    <KTPortlet
                            v-bind="{
            }"
                    >
                        <template v-slot:body>
                            <b-button-toolbar
                                    aria-label="Toolbar with button groups and input groups"
                            >
                                <b-button-group size="sm" class="mr-1">
                                    <b-button :to="{'name':'document-edit'}" class="mr-2 btn-primary">Nuovo documento</b-button>
                                    <!--<v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>-->
                                </b-button-group>
                            </b-button-toolbar>
                        </template>
                    </KTPortlet>
                    <!--end:: Widgets/Activity-->
                </div>

            </div>
        </div>
        <div v-permission="'contract.view'">
            <div class="row">
                <div class="col-md-12">
                    <!--begin:: Widgets/Activity-->
                    <KTPortlet
                            v-bind="{
            }" v-bind:class="{ 'kt-spinner-f': loading }"
                    >
                        <template v-slot:body>

                            <v-card>
                                <v-card-title>
                                    Elenco
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            label="Cerca"
                                            single-line
                                            hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                        v-model="selected"
                                        :headers="headers"
                                        :items="items"
                                        :single-select="singleSelect"
                                        :sort-by="['updated_at']"
                                        :sort-desc="[true]"
                                        multi-sortNO
                                        item-key="id"
                                        show-selectNO
                                        class="elevation-1"
                                        :search="search"
                                >
                                    <template v-slot:item.created_at="{ item }">
                                        <span>{{item.created_at | formatDate}}</span>
                                    </template>
                                    <template v-slot:item.updated_at="{ item }">
                                        <span>{{item.updated_at | formatDate}}</span>
                                    </template>
<!--                                    <template v-slot:top>
                                        <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                                    </template>-->
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon v-if="item.form.length > 10"  v-permission="'contract.fill'"
                                                small
                                                class="mr-2"
                                                @click="fillForm(item)"
                                        >
                                            mdi-email
                                        </v-icon>
                                        <v-icon  v-permission="'contract.fill'"
                                                small
                                                class="mr-2"
                                                @click="showSubmissions(item)"
                                        >
                                            mdi-playlist-check
                                        </v-icon>
                                        <v-icon  v-permission="'contract.edit'"
                                                small
                                                class="mr-2"
                                                @click="editItem(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <div class="inlineblock"><click-confirm>
                                        <v-icon  v-permission="'contract.delete'"
                                                small
                                                @click="deleteItem(item)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                            </click-confirm></div>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </template>
                    </KTPortlet>
                    <!--end:: Widgets/Activity-->
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import KTCodePreview from "@/views/partials/content/CodePreview.vue";
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import ApiService from "@/common/api.service";
    import UserService from "../../../common/user.service";

    export default {
        data() {
            return {
                loading:false,
                singleSelect: false,
                selected: [],
                search: '',
                headers: [
                    {text: 'ID', value: 'id'},
                    {
                        text: 'Label',
                        align: 'left',
                        value: 'label',
                    },
                    {text: 'Updated At', value: 'updated_at'},
                    {text: 'Created At', value: 'created_at'},

                ],
                items: [

                ],
            }
        },
        components: {
            KTPortlet
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Documenti", route: "/document"},
                {title: "Lista documenti", route: "list"}
            ]);

        },

        created () {
            this.initialize()
        },

        methods: {
            reloadList(){
                this.loading = true;
                ApiService.get('contracts')
                    .then(({ data }) => {
                        this.items = data.rows;
                        this.loading = false;
                    })
                    .catch(({ response }) => {
                        //context.commit(SET_ERROR, response.data.errors);
                    });
            },
            initialize () {
                if(UserService.hasPermission(['contract.fill','contract.edit','contract.delete'])){
                    this.headers.push({ text: 'Actions', value: 'actions', sortable: false });
                }
                this.reloadList();
            },

            fillForm (item) {
                this.$router.push({ name: "document-form", 'params':{'id':item.id} });
            },
            showSubmissions (item) {
                this.$router.push({ name: "document-history", 'query':{'filter_contract':item.id} });
            },
            editItem (item) {
                this.$router.push({ name: "document-edit", 'params':{'id':item.id} });
            },

            deleteItem (item) {
                //const index = this.desserts.indexOf(item)
                ApiService.delete('contract/'+item.id,{'id':item.id}).then(() => this.reloadList());
            },


        },
    };
</script>
